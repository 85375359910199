<template>
    <div class="container mt-5 tornado-not-found" style="display: none;">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="text-center">
                    <h3 class="mt-3">
                        <i class="bi bi-x-octagon"></i> No risk with Cost TPE expected found.
                    </h3>
                    <p class="text-muted">The requested snapshot does not exist, or you do not have access to it.</p>
                </div>
            </div>
        </div>
    </div>

    <div id="cChart_Tornado" style="width:100%; height:100%;"></div>
</template>

<script setup type="ts">
    import { getOrCreateDataObject } from 'o365-dataobject';

    const props = defineProps({
        load: function(options) {
            var dsUncertainties = this.dsUncertainties,
                dsOrgUnits = this.dsOrgUnits,
                renderChart = this.renderChart,
                // notFound = document.querySelector("div.tornado-not-found"),
                chartTitle = '';

            //reset
            // notFound.style.display = "none";
            // cChart_Tornado.innerHTML = "";
            if (options.OrgUnit_ID) {
                dsOrgUnits.recordSource.whereClause = "ID = " + options.OrgUnit_ID;
                dsOrgUnits.load();
            } else if (options.Risk_IDs) {
                dsUncertainties.recordSource.whereClause = `CostTPEExpected IS NOT NULL AND ID IN (${options.Risk_IDs})`;
                dsUncertainties.load();
            }

            dsOrgUnits.on("DataLoaded", function() {
                if(!options.Risk_IDs) {
                    chartTitle = `${dsOrgUnits.current.OrgUnit} - `;
                }
                dsUncertainties.recordSource.whereClause = `CostTPEExpected IS NOT NULL AND OrgUnitIdPath LIKE '${dsOrgUnits.current.IdPath}%'`;
                dsUncertainties.load();
            });

            dsUncertainties.on("DataLoaded", function() {
                if (dsUncertainties.data.length == 0) {
                    // notFound.style.display = "block";
                    return;
                };
                if (!options.OrgUnit_ID && !options.Risk_IDs) {
                    // notFound.style.display = "block";
                    return;
                };

                // if(dsUncertainties.recordSource.whereClause) {
                    renderChart(dsUncertainties.data, chartTitle);
                // } else {
                    // dsUncertainties.recordSource.whereClause = `CostTPEExpected IS NOT NULL AND OrgUnitIdPath LIKE '${dsOrgUnits.current.IdPath}%'`;
                    // dsUncertainties.load();
                // };
            });
        },
        renderChart: function(riskData, chartTitle) {
            let vRisks = [],
                vCoefficients = [];

            riskData.forEach(function(pRow) {
                vRisks.push(pRow['Name'] + "<br>" + pRow["NameAndTitle"]);
                vCoefficients.push(Number(pRow['CostTPEExpected'].toFixed(2))); //.toFixed(2)
            });

            Highcharts.chart('cChart_Tornado', {
                chart: {
                    type: 'bar',
                    plotBorderWidth: 1,
                },
                title: {
                    text: `${chartTitle}Total Capital Profile`,
                },
                subtitle: {
                    text: 'Regression Coefficients',
                    margin: 20,
                },
                xAxis: {
                    categories: vRisks,
                    title: {
                        text: null
                    },
                    gridLineWidth: 1,
                    tickWidth: 1,
                    tickLength: 8,
                    tickmarkPlacement: 'on',
                },
                yAxis: {
                    min: -0.05,
                    title: {
                        text: null,
                    },
                    labels: {
                        overflow: 'justify',
                        rotation: -90,
                    },
                    gridLineWidth: 1,
                    tickWidth: 1,
                    tickLength: 8,
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: false
                        },
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Coefficient Value',
                    pointWidth: 20,
                    data: vCoefficients,
                    color: {
                        linearGradient: {
                            x1: 0,
                            x2: 0,
                            y1: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#750920'],
                            [1, '#b80f31']
                        ]
                    },
                    borderWidth: 1,
                    borderColor: 'black'
                }]
            });
        },
        dsUncertainties: getOrCreateDataObject({
            "id": "dsUncertainties_" + crypto.randomUUID(),
            "viewName": "aviw_RiskManager_Uncertainties",
            "distinctRows": false,
            "maxRecords": 10,
            "selectFirstRowOnLoad": true,
            "fields": [
                {"name": "Name"},
                {"name": "CostTPEExpected", "sortOrder": 1,"sortDirection": "desc"},
                {"name": "NameAndTitle"},
            ]
        }),
        dsOrgUnits: getOrCreateDataObject({
            "id": "dsOrgUnits_" + crypto.randomUUID(),
            "viewName": "stbv_System_OrgUnits",
            "distinctRows": false,
            "maxRecords": 1,
            "selectFirstRowOnLoad": true,
            "fields": [
                {"name": "OrgUnit"},
                {"name": "IdPath"}
            ]
        })
    });
</script>